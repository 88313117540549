import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_URL;

const apiKey = process.env.REACT_APP_API_KEY;

function CityListing() {
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const TruncatedParagraph = (text, maxLength) => {
    const truncatedText =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    return truncatedText;
  };
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/city/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdata(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);
  console.log(data);
  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header */}

          {/* header end  */}
          {/* header end  */}

          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content pt-0">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
              </div>
              <div className="container dasboard-container">
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* col-list-wrap */}
                    <section className="gray-bg small-padding ">
                      <div className="container">
                        <div className="row">
                          {/* search sidebar*/}
                          <div className="col-md-4">
                            {/*box-widget*/}

                            {/*box-widget end */}
                            {/*box-widget*/}
                            <div
                              className="box-widget fl-wrap"
                              style={{ marginTop: "65px" }}
                            >
                              <div className="banner-widget fl-wrap">
                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                  <div
                                    className="bg  "
                                    data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                  />
                                </div>
                                <div className="banner-widget_content">
                                  <h5>
                                    Do you want to join our real estate network?
                                  </h5>
                                  <a
                                    href="city"
                                    className="btn float-btn color-bg small-btn"
                                  >
                                    Add City
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/*box-widget end */}
                          </div>
                          {/* search sidebar end*/}
                          <div className="col-md-8">
                            {/* list-main-wrap-header*/}
                            <div className="list-main-wrap-header box-list-header fl-wrap">
                              {/* list-main-wrap-title*/}
                              <div className="list-main-wrap-title">
                                <h2>
                                  <span>City Listing </span>
                                  <strong>{data.length}</strong>
                                </h2>
                              </div>
                              {/* list-main-wrap-title end*/}
                              {/* list-main-wrap-opt*/}
                              <div className="list-main-wrap-opt">
                                {/* price-opt*/}
                                <div className="price-opt">
                                  <span className="price-opt-title">
                                    Sort by:
                                  </span>
                                  <div className="listsearch-input-item">
                                    <select
                                      data-placeholder="Popularity"
                                      className="chosen-select no-search-select"
                                    >
                                      <option>Popularity</option>
                                      <option>State</option>
                                      <option>City</option>
                                      <option>Tagline</option>
                                    </select>
                                  </div>
                                </div>
                                {/* price-opt end*/}
                              </div>
                              {/* list-main-wrap-opt end*/}
                            </div>
                            {/* list-main-wrap-header end*/}
                            {/* dashboard-listings-wrap*/}
                            <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ display: "inline-grid" }}
                                >
                                  {data.length &&
                                    data.map((item, index) => (
                                      <div
                                        className="listing-item"
                                        key={index}
                                        style={{ width: "100%" }}
                                      >
                                        <article className="geodir-category-listing fl-wrap">
                                          <div className="geodir-category-img fl-wrap">
                                            <a
                                              href="#"
                                              className="geodir-category-img_item"
                                            >
                                              <img
                                                style={{
                                                  height: "128px",
                                                  width: "100px",
                                                }}
                                                key={index}
                                                src={
                                                  item.photo == ""
                                                    ? "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                    : `${apiUrl}/uploads/${item.photo}`
                                                }
                                                alt
                                              />
                                            </a>
                                          </div>
                                          <div
                                            className="geodir-category-content fl-wrap"
                                            style={{ height: "170px" }}
                                          >
                                            {/* <div
                                              className="card-verified tolt"
                                              data-microtip-position="left"
                                              data-tooltip="Verified"
                                            >
                                              <i className="fa-solid fa-user-check" />
                                            </div> */}
                                            <div className="agent_card-title fl-wrap">
                                              <h4>
                                                {item.cities.name}{" "}
                                                <span
                                                  style={{ color: "#808080" }}
                                                >
                                                  {item.states.name}
                                                </span>{" "}
                                                :{" "}
                                                <span
                                                  style={{
                                                    color: "pink",
                                                    fontSize: "11px",
                                                  }}
                                                >
                                                  <i className="fa-solid fa-tag" />{" "}
                                                  {item.tagline}
                                                </span>

                                              </h4>
                                              <div className="geodir-category-location fl-wrap">
                                                <div
                                                  className="listing-rating card-popup-rainingvis"
                                                  data-starrating2={4}
                                                >
                                                  <span
                                                    className="re_stars-title"
                                                    style={{
                                                      left: "15px",
                                                      width: "500px",
                                                    }}
                                                  >
                                                    "
                                                    {TruncatedParagraph(
                                                      item.about,
                                                      200
                                                    )}
                                                    "
                                                  </span>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              className="small-facts fl-wrap"
                                              style={{
                                                marginTop: "50px",
                                                paddingLeft: "250px",
                                              }}
                                            >
                                              <ul>

                                                <li>
                                                  <a href={item.mapUrl}>
                                                    <span
                                                      style={{
                                                        fontSize: "11px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <strong>
                                                        <i className="fa-solid fa-location" />{" "}
                                                      </strong>
                                                      Map
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <span
                                                    style={{ fontSize: "11px" }}
                                                  >
                                                    <strong>
                                                      <i className="fa-solid fa-eye" />{" "}
                                                    </strong>
                                                    View
                                                  </span>
                                                </li>
                                                <li>
                                                  <span
                                                    style={{ fontSize: "11px" }}
                                                  >
                                                    <strong>
                                                      <i class="fa-solid fa-building"></i>
                                                    </strong>
                                                    Property
                                                  </span>
                                                </li>
                                                <li style={{ cursor: 'pointer' }}>
                                                  <div
                                                    onClick={(e) => {
                                                      navigate("/editcity",
                                                        { state: item }
                                                      )
                                                    }}
                                                  >
                                                    <i className="fa-solid fa-pen-to-square" style={{ color: '#686868' }} />
                                                  </div>
                                                </li>

                                              </ul>
                                            </div>
                                          </div>
                                         
                                        </article>
                                      </div>
                                    ))}
                                </div>
                                {/*  agecy card item */}
                              </div>
                              {/* dashboard-listings-wrap end*/}
                              {/* pagination*/}

                              {/* pagination end*/}
                            </div>
                            {/* col-md 8 end */}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-50px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
}

export default CityListing;
