import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function EditCity() {
    const location = useLocation();
    const item = location.state;
    console.log('item =>', item)

    const initialFormData = {
        id: item.id,
        state: item.state,
        city: item.city,
        tagline: item.tagline,
        mapUrl: item.mapUrl,
        about: item.about,
        photo: item.photo,
        photo1: item.photo1,
        photo2: item.photo2,
        photo3: item.photo3,
        photo4: item.photo4,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [blogPhoto, setBlogPhoto] = useState(null);
    const [blogPhoto1, setBlogPhoto1] = useState(null);
    const [blogPhoto2, setBlogPhoto2] = useState(null);
    const [blogPhoto3, setBlogPhoto3] = useState(null);
    const [blogPhoto4, setBlogPhoto4] = useState(null);
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");

    const [cityOptions, setCityOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [stateId, setStateId] = useState(formData.state);

    useEffect(() => {
        fetch(`${apiUrl}/employee/allStates/207`, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && Array.isArray(data.data)) {
                    console.log("dataaaaaaaaaaa", data.data);
                    setStateOptions(data.data);
                } else {
                    console.error("API response does not contain an array:", data);
                }
            })
            .catch((error) => {
                console.error("Error fetching masters data:", error);
            });
    }, [apiKey, apiUrl]);

    useEffect(() => {
        fetch(`${apiUrl}/employee/allcities/${stateId}`, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && Array.isArray(data.data)) {
                    console.log("dataaaaaaaaaaa", data.data);
                    setCityOptions(data.data);
                } else {
                    console.error("API response does not contain an array:", data);
                }
            })
            .catch((error) => {
                console.error("Error fetching masters data:", error);
            });
    }, [stateId, apiKey, apiUrl]);

    // A function to handle the change of the input fields
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file") {
            setFormData((prev) => ({ ...prev, [name]: files[0] }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    // A function to handle the submit of the property form
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (formData[key] !== null) {
                    formDataToSend.append(key, formData[key]);
                }
            }

            const response = await fetch(`${apiUrl}/city/edit`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            // window.location.reload()
            navigate("/city-listing");
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div>
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/*=============== favicons ===============*/}
            <link rel="shortcut icon" href="images/favicon.ico" />
            {/*loader*/}
            {/* <div className="loader-wrap">
                <div className="loader-inner">
                    <svg>
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
                                <feColorMatrix
                                    in="blur"
                                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                                    result="gooey"
                                />
                                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div> */}
            {/*loader end*/}
            {/* main */}
            <div>
                {/* wrapper  */}
                <div id="wrapper">
                    {/* dashbard-menu-wrap */}
                    <div className="dashbard-menu-overlay" />
                    {/* content */}
                    <div className="dashboard-content pt-0">
                        <div className="dashboard-menu-btn color-bg">
                            <span>
                                <i className="fas fa-bars" />
                            </span>
                            Dasboard Menu
                        </div>
                        <div className="container dasboard-container">
                            {/* dashboard-title end */}
                            {/* dasboard-wrapper*/}
                            <div className="dasboard-wrapper fl-wrap no-pag">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="list-searh-input-wrap-title fl-wrap">
                                            <i className="fa-solid fa-city" />
                                            <span>Add Property City </span>
                                        </div>
                                        {/* <div className="list-searh-input-wrap-title fl-wrap">
                            <i className="fa-solid fa-sliders-h" />
                            <span>Search Agency</span>
                          </div> */}

                                        {/*box-widget*/}
                                        <div
                                            className="block-box fl-wrap search-sb"
                                            id="filters-column"
                                        >
                                            {/* listsearch-input-item */}
                                            <div className="listsearch-input-item">
                                                <label>
                                                    {" "}
                                                    <i className="fa-solid fa-image" /> City Photo
                                                </label>
                                                <hr style={{ marginTop: 0, height: "0.5px" }} />
                                                {/* <span>
                              {" "}
                              <i className="fa-solid fa-image" /> City Cover 1{" "}
                            </span> */}
                                                <div style={{ margin: "10px  0px" }}>
                                                    <input
                                                        type="file"
                                                        className="col"
                                                        name="photo"
                                                        onChange={(e) => {
                                                            const files = e.target.files;
                                                            if (files.length > 0) {
                                                                const propertyFile = files[0];
                                                                if (propertyFile.type.startsWith("image/")) {
                                                                    setBlogPhoto(propertyFile);
                                                                    handleChange(e);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {blogPhoto && (
                                                        <img
                                                            src={URL.createObjectURL(blogPhoto)}
                                                            alt="Selected File"
                                                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                                                        />
                                                    )}

                                                </div>
                                                <div style={{ margin: "10px  0px" }}>
                                                    <input
                                                        type="file"
                                                        className="col"
                                                        name="photo1"
                                                        onChange={(e) => {
                                                            const files = e.target.files;
                                                            if (files.length > 0) {
                                                                const propertyFile = files[0];
                                                                if (propertyFile.type.startsWith("image/")) {
                                                                    setBlogPhoto1(propertyFile);
                                                                    handleChange(e);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {blogPhoto1 && (
                                                        <img
                                                            src={URL.createObjectURL(blogPhoto1)}
                                                            alt="Selected File"
                                                            className="m-0"
                                                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                                                        />
                                                    )}

                                                </div>
                                                <div style={{ margin: "10px  0px" }}>
                                                    <input
                                                        type="file"
                                                        className="col"
                                                        name="photo2"
                                                        onChange={(e) => {
                                                            const files = e.target.files;
                                                            if (files.length > 0) {
                                                                const propertyFile = files[0];
                                                                if (propertyFile.type.startsWith("image/")) {
                                                                    setBlogPhoto2(propertyFile);
                                                                    handleChange(e);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {blogPhoto2 && (
                                                        <img
                                                            src={URL.createObjectURL(blogPhoto2)}
                                                            alt="Selected File"
                                                            className="m-0"
                                                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                                                        />
                                                    )}

                                                </div>
                                                <div style={{ margin: "10px  0px" }}>
                                                    <input
                                                        type="file"
                                                        className="col"
                                                        name="photo3"
                                                        onChange={(e) => {
                                                            const files = e.target.files;
                                                            if (files.length > 0) {
                                                                const propertyFile = files[0];
                                                                if (propertyFile.type.startsWith("image/")) {
                                                                    setBlogPhoto3(propertyFile);
                                                                    handleChange(e);
                                                                }
                                                            }
                                                        }}
                                                    />

                                                    {blogPhoto3 && (
                                                        <img
                                                            src={URL.createObjectURL(blogPhoto3)}
                                                            alt="Selected File"
                                                            className="m-0"
                                                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                                                        />
                                                    )}

                                                </div>
                                                <div style={{ margin: "10px  0px" }}>
                                                    <input
                                                        type="file"
                                                        className="col"
                                                        name="photo4"
                                                        onChange={(e) => {
                                                            const files = e.target.files;
                                                            if (files.length > 0) {
                                                                const propertyFile = files[0];
                                                                if (propertyFile.type.startsWith("image/")) {
                                                                    setBlogPhoto4(propertyFile);
                                                                    handleChange(e);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {blogPhoto4 && (
                                                        <img
                                                            src={URL.createObjectURL(blogPhoto4)}
                                                            alt="Selected File"
                                                            className="m-0"
                                                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                                                        />
                                                    )}

                                                </div>
                                                {/* <input
                type="text"
                onclick="this.select()"
                placeholder="Name , agency..."
                defaultValue
              /> */}
                                                {/* <input
                          type="text"
                          className="col"
                          placeholder="Type..."
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        /> */}
                                            </div>

                                            {/* listsearch-input-item end*/}
                                            {/* listsearch-input-item */}

                                            {/* listsearch-input-item end*/}
                                            {/* listsearch-input-item */}

                                            {/* listsearch-input-item end*/}

                                            {/* <div className="checkbox-container">
                  {masters.map((department, index) => (
                    <div key={index} className="checkbox-item">
                      <input
                        id={`check-${department.id}`}
                        type="checkbox"
                        name="masters"
                        value={department.id}
                        onChange={(e) => {
                          let data = linkedMasters;
                          if (e.target.checked) {
                            if (data !== "") {
                              setLinkedMasters(
                                data + "," + e.target.value
                              );
                            } else {
                              setLinkedMasters(e.target.value);
                            }
                          } else {
                            data = data.split(",");
                            data = data.filter(
                              (d) => d !== e.target.value
                            );
                            setLinkedMasters(data.join(","));
                          }
                        }}
                      />
                      <label htmlFor={`check-${department.id}`}>
                        {department.name}
                      </label>
                    </div>
                  ))}
                </div> */}

                                            <div className="msotw_footer">
                                                <a
                                                    onClick={handleSubmit}
                                                    className="btn small-btn float-btn color-bg"
                                                >
                                                    Add City Photo
                                                </a>
                                                {/* <div className="reset-form reset-btn">
                {" "}
                <i className="far fa-sync-alt" /> Reset Filters
              </div> */}
                                            </div>
                                        </div>
                                        {/*box-widget end */}
                                        {/*box-widget*/}
                                        <div
                                            className="box-widget fl-wrap"
                                        // style={{ marginTop: "70px" }}
                                        >
                                            <div className="banner-widget fl-wrap">
                                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                                    <div
                                                        className="bg  "
                                                        data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                                    />
                                                </div>
                                                <div className="banner-widget_content">
                                                    <h5>Ideas are easy. Implementation is hard</h5>
                                                    <a
                                                        href="city-listing"
                                                        className="btn float-btn color-bg small-btn"
                                                    >
                                                        City Listing
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {/*box-widget end */}
                                    </div>{" "}
                                    <div className="col-md-8">
                                        <div className="dasboard-widget-title fl-wrap">
                                            <h5>
                                                <i className="fa-solid fa-city" />
                                                Add City
                                            </h5>
                                        </div>
                                        <div className="dasboard-widget-box fl-wrap">
                                            <form>
                                                <div className="custom-form">
                                                    <div className="col-6">
                                                        <label>
                                                            State{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-user" />
                                                            </span>
                                                        </label>
                                                        <select
                                                            className="selectBox"
                                                            name="state"
                                                            value={formData.state}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setStateId(e.target.value);
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            {stateOptions.map((department) => (
                                                                <option
                                                                    key={department.id}
                                                                    value={department.id}
                                                                >
                                                                    {department.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-6">
                                                        <label>
                                                            City{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-user" />
                                                            </span>
                                                        </label>
                                                        <select
                                                            className="selectBox"
                                                            name="city"
                                                            value={formData.city}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        >
                                                            <option>Select</option>
                                                            {cityOptions.map((department) => (
                                                                <option
                                                                    key={department.id}
                                                                    value={department.id}
                                                                >
                                                                    {department.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-6">
                                                        <label>
                                                            Tagline{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-pen" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="w-100"
                                                            value={formData.tagline}
                                                            name="tagline"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>
                                                            Map URL{" "}
                                                            <span className="dec-icon">
                                                                <i className="fa-solid fa-pen" />
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="w-100"
                                                            value={formData.mapUrl}
                                                            name="mapUrl"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>About </label>
                                                        <textarea
                                                            cols={40}
                                                            rows={3}
                                                            className="w-100"
                                                            value={formData.about}
                                                            style={{ marginBottom: 20 }}
                                                            name="about"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    </div>
                                                    <p style={{ paddingLeft: "570px" }}>Photo Preview</p>
                                                    <div className="col">

                                                        {blogPhoto && (
                                                            <img
                                                                src={URL.createObjectURL(blogPhoto)}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                        {!blogPhoto && (
                                                            <img
                                                                src={`${apiUrl}/uploads/${formData.photo}`}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                    </div>

                                                    <div className="col">
                                                        {" "}
                                                        {blogPhoto1 && (
                                                            <img
                                                                src={URL.createObjectURL(blogPhoto1)}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                        {!blogPhoto1 && (
                                                            <img
                                                                src={`${apiUrl}/uploads/${formData.photo1}`}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                    </div>

                                                    <div className="col">
                                                        {blogPhoto2 && (
                                                            <img
                                                                src={URL.createObjectURL(blogPhoto2)}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                        {!blogPhoto2 && (
                                                            <img
                                                                src={`${apiUrl}/uploads/${formData.photo2}`}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col">
                                                        {" "}
                                                        {blogPhoto3 && (
                                                            <img
                                                                src={URL.createObjectURL(blogPhoto3)}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                        {!blogPhoto3 && (
                                                            <img
                                                                src={`${apiUrl}/uploads/${formData.photo3}`}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* <span>
                            {" "}
                            <i className="fa-solid fa-image" /> City Cover 2{" "}
                          </span>
                          <input
                            type="file"
                            className="upload"
                            name="photo1"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files.length > 0) {
                                const propertyFile = files[0];
                                if (propertyFile.type.startsWith("image/")) {
                                  setBlogPhoto1(propertyFile);
                                  handleChange(e);
                                }
                              }
                            }}
                          /> */}

                                                    {/* <span>
                            {" "}
                            <i className="fa-solid fa-image" /> City Cover 3{" "}
                          </span>
                          <input
                            type="file"
                            className="upload"
                            name="photo2"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files.length > 0) {
                                const propertyFile = files[0];
                                if (propertyFile.type.startsWith("image/")) {
                                  setBlogPhoto2(propertyFile);
                                  handleChange(e);
                                }
                              }
                            }}
                          /> */}

                                                    {/* <span>
                            {" "}
                            <i className="fa-solid fa-image" /> City Cover 4{" "}
                          </span>
                          <input
                            type="file"
                            className="upload"
                            name="photo3"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files.length > 0) {
                                const propertyFile = files[0];
                                if (propertyFile.type.startsWith("image/")) {
                                  setBlogPhoto3(propertyFile);
                                  handleChange(e);
                                }
                              }
                            }}
                          /> */}

                                                    {/* <span>
                            {" "}
                            <i className="fa-solid fa-image" /> City Cover 5{" "}
                          </span>
                          <input
                            type="file"
                            className="upload"
                            name="photo4"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files.length > 0) {
                                const propertyFile = files[0];
                                if (propertyFile.type.startsWith("image/")) {
                                  setBlogPhoto4(propertyFile);
                                  handleChange(e);
                                }
                              }
                            }}
                          /> */}
                                                    <div className="col">
                                                        {" "}
                                                        {blogPhoto4 && (
                                                            <img
                                                                src={URL.createObjectURL(blogPhoto4)}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                        {!blogPhoto4 && (
                                                            <img
                                                                src={`${apiUrl}/uploads/${formData.photo3}`}
                                                                alt="Selected File"
                                                                style={{
                                                                    width: "200px",
                                                                    margin: "10px",
                                                                    height: "150",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <button
                                                    className="btn color-bg float-btn m-0 text-white"
                                                    onClick={handleSubmit}
                                                >
                                                    Edit City
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* dasboard-wrapper end */}
                        </div>
                        {/* dashboard-footer */}
                        <div
                            className="dashboard-footer"
                            style={{ left: 0, bottom: "-180px" }}
                        >
                            <div className="dashboard-footer-links fl-wrap">
                                <span>Helpfull Links:</span>
                                <ul>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/about">About</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/pricing">
                                            Pricing Plans
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#main" className="dashbord-totop  custom-scroll-link">
                                <i className="fa-solid fa-caret-up" />
                            </a>
                        </div>
                        {/* dashboard-footer end */}
                    </div>
                    {/* content end */}
                    <div className="dashbard-bg gray-bg" />
                </div>
                {/* wrapper end */}
            </div>
            {/* Main end */}
            {/*=============== scripts  ===============*/}
        </div>
    );
}
export default EditCity;
