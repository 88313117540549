import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import "../../index.css";
import { useLocation } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const Token = localStorage.getItem("Token");

const EditBanner = () => {
    const location = useLocation();
    const item = location.state

    const initialFormData = {
        id: item.id,
        imageTitle: item.imageTitle,
        imageDescription: item.imageDescription,
        link: item.link,
        position: item.position,
        imageForWeb: item.imageForWeb
    }

    const [formData, setFormData] = useState(initialFormData)
    const [bannerImg, setBannerImg] = useState(null)


    // A function to handle the change of the input fields
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file") {
            setFormData((prev) => ({ ...prev, [name]: files[0] }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }


    };

    // A function to handle the submit of the property form
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();

            for (const key in formData) {
                if (formData[key] !== null) {
                    formDataToSend.append(key, formData[key]);
                }
            }

            const response = await fetch(`${apiUrl}/banner/editBanner`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            // setFormData(initialFormData)
            // navigate("/Master");
            setTimeout(() => {
                window.location.href = "/banners";
            }, 2000)
        } catch (error) {
            toast.error(error.message);
        }
    };

    // useEffect(() => {

    // }, [])

    return (
        <>
            {/*=============== basic  ===============*/}
            <meta charSet="UTF-8" />
            <title>Hauzzi Beta Website UI/UX design by Webkype </title>
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content />
            <meta name="description" content />
            {/*=============== css  ===============*/}
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/*=============== favicons ===============*/}
            <link
                rel="shortcut icon"
                href="https://hauzzi.webkype.net/images/favicon.ico"
            />


            <div id="main">

                <div id="wrapper">
                    {/* dashbard-menu-wrap */}
                    <div className="dashbard-menu-overlay" />
                    {/* content */}
                    <div className="dashboard-content pt-0">
                        <div className="dashboard-menu-btn color-bg">
                            <span>
                                <i className="fa-solid fa-bars" />
                            </span>
                            Dasboard Menu
                        </div>
                        <div className="container dasboard-container">
                            {/* dashboard-title end */}
                            {/* dasboard-wrapper*/}
                            <div className="dasboard-wrapper fl-wrap no-pag">
                                <div className="row">
                                    <div
                                        className="col-md-8"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            width: "100%",
                                        }}
                                    >
                                        <div style={{ width: "50%" }}>

                                            <div className="dasboard-widget-title fl-wrap">
                                                {" "}
                                                <h5>
                                                    <i className="fa-solid fa-key" />
                                                    Banner Info{" "}
                                                </h5>{" "}
                                            </div>{" "}
                                            <div className="dasboard-widget-box fl-wrap">
                                                {" "}
                                                <form className="custom-form" onSubmit={handleSubmit}>
                                                    {" "}
                                                    <label>
                                                        Position
                                                    </label>{" "}
                                                    <select
                                                        name="position"
                                                        value={formData.position}
                                                        onChange={(e) => { handleChange(e) }}
                                                        style={{
                                                            height: "4vh",
                                                            display: "inline",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            border: "1px solid #808080",
                                                            transition: "box-shadow 0.3s ease",
                                                            outline: "none  ",
                                                            width: "80%",
                                                            height: "50px",
                                                            color: "#808080",
                                                            background: "#f5f7fb",
                                                            marginBottom: "10px",
                                                        }}
                                                        required
                                                    >
                                                        <option>Select Banner Position</option>
                                                        <option value="MainSliderBanner">Main Slider Banner</option>
                                                        <option value="SecondRightBanner">Second Right Banner</option>
                                                        <option value="ThirdLeftBanner">Third Left Banner</option>
                                                        <option value="LeftOfEndBanner">Left Of End Banners</option>
                                                        <option value="RightOfEndBanner">Right Of End Banners</option>
                                                    </select>
                                                    <label>
                                                        Image Title{" "}
                                                        <span className="dec-icon">
                                                            <i className="fa-solid fa-user" />{" "}
                                                        </span>{" "}
                                                    </label>{" "}
                                                    <input
                                                        type="text"
                                                        name="imageTitle"
                                                        value={formData.imageTitle}
                                                        required
                                                        style={{ width: "100%" }}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />{" "}
                                                    {/* <label>
                                                        Link{" "}
                                                        <span className="dec-icon">
                                                            <i className="fa-solid fa-link" />{" "}
                                                        </span>{" "}
                                                    </label>{" "}
                                                    <input
                                                        type="text"
                                                        placeholder="Link"
                                                        name="link"
                                                        style={{ width: "100%" }}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    /> */}

                                                    <label>Tagline </label>
                                                    <textarea
                                                        type="text"
                                                        name="imageDescription"
                                                        cols={30}
                                                        rows={2}
                                                        value={formData.imageDescription}
                                                        style={{ marginBottom: 20, width: "100%" }}
                                                        defaultValue={""}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                    />

                                                    <button
                                                        type="submit"
                                                        className="btn color-bg float-btn m-0"
                                                        onClick={() => {
                                                            setFormData((prev) => {
                                                                const obj = { ...prev }

                                                                return obj
                                                            })
                                                        }}
                                                    >
                                                        Edit Banner
                                                    </button>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-md-4" style={{ width: '45%' }}>
                                            <div className="dasboard-widget-title fl-wrap">
                                                <h5>
                                                    <i className="fa-solid fa-user-circle" />
                                                    Change Banner
                                                </h5>
                                            </div>

                                            <div className="dasboard-widget-box nopad-dash-widget-box fl-wrap" style={{ height: '300px', width: '100%' }} >
                                                <div className="bg-wrap bg-parallax-wrap-gradien"  >

                                                    {!bannerImg && <img
                                                        className="bg"
                                                        src={`${apiUrl}/uploads/${formData.imageForWeb}`}
                                                    />}

                                                    {bannerImg &&
                                                        <img
                                                            src={URL.createObjectURL(bannerImg)}
                                                            alt="Selected File"
                                                            style={{
                                                                maxWidth: "100%",
                                                            }}
                                                        />
                                                    }
                                                </div>
                                                <div className="change-photo-btn cpb-2  ">
                                                    <div className="photoUpload color-bg">
                                                        <span>
                                                            {" "}
                                                            <i className="fa-solid  fa-pen-to-square" /> Upload Banner{" "}
                                                        </span>
                                                        {/* <input type="file" className="upload" /> */}
                                                        <input
                                                            type="file"
                                                            className="upload"
                                                            name="imageForWeb"

                                                            onChange={(e) => {
                                                                const files = e.target.files;
                                                                const bannerFile = files[0];
                                                                if (bannerFile.type.startsWith("image/") && bannerFile.size <= 1024 * 1024) {
                                                                    setBannerImg(bannerFile)
                                                                    handleChange(e)
                                                                } else {
                                                                    toast.error("Upload Image Files Less Than 1 Mb")
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="dasboard-widget-title fl-wrap"
                                                style={{ marginTop: 30 }}
                                            >
                                                <h5 style={{ color: 'red' }} >
                                                    Note before uploading image
                                                </h5>
                                            </div>
                                            <div className="dasboard-widget-box fl-wrap">
                                                <div className="custom-form">
                                                    <p>* Please ensure the image is 1900 x 900px for the Main Slider Banner.</p>
                                                    <p>* Image size should be below 1 MB.</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>

                            <div
                                className="dashboard-footer"
                                style={{ left: "-95px", bottom: "-50px", right: "-95px" }}
                            >
                                <div className="dashboard-footer-links fl-wrap">
                                    <span>Helpfull Links:</span>
                                    <ul>
                                        <li>
                                            <a href="https://hauzi-user.vercel.app/about">About</a>
                                        </li>
                                        <li>
                                            <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                                        </li>
                                        <li>
                                            <a href="https://hauzi-user.vercel.app/pricing">
                                                Pricing Plans
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://hauzi-user.vercel.app/contact">
                                                Contacts
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                                        </li>
                                    </ul>
                                </div>
                                <a href="#main" className="dashbord-totop  custom-scroll-link">
                                    <i className="fa-solid fa-caret-up" />
                                </a>
                            </div>

                            {/* dashboard-footer end */}
                        </div>
                        {/* content end */}
                        <div className="dashbard-bg gray-bg" />
                    </div>
                    {/* wrapper end */}
                </div>
                {/* Main end */}
                {/*=============== scripts  ===============*/}
            </div>
        </>
    );
};

export default EditBanner;
