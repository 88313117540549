import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

function EditFaq() {
    const location = useLocation()
    const item = location.state;
    console.log('item =>', item)
    const initialFormData = {
        id: item.id,
        query: item.query,
        answer: item.answer,
        category: item.category,
    };

    const [formData, setFormData] = useState(initialFormData);
    const navigate = useNavigate();

    const editorRef = useRef(null);

    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");

    // A function to handle the change of the input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // A function to handle the submit of the property form
    const handleSubmit = async (e) => {
        e.preventDefault();
        const answer = editorRef.current.value
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (formData[key] !== null) {
                    formDataToSend.append(key, formData[key]);
                }
            }
            // formDataToSend.append("answer", `${answer}`);
            const response = await fetch(`${apiUrl}/faq/edit`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            // window.location.reload()
            navigate("/faq");
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div>
            {/*=============== basic  ===============*/}
            <meta charSet="UTF-8" />
            <title>Hauzzi Beta Website UI/UX design by Webkype </title>
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content />
            <meta name="description" content />
            {/*=============== css  ===============*/}
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/*=============== favicons ===============*/}
            <link rel="shortcut icon" href="images/favicon.ico" />
            {/* main */}
            <div id="main">
                {/* header */}
                {/* wrapper  */}
                <div id="wrapper">
                    {/* dashbard-menu-wrap */}
                    <div className="dashbard-menu-overlay" />
                    {/* content */}
                    <div className="dashboard-content pt-0">
                        <div className="dashboard-menu-btn color-bg">
                            <span>
                                <i className="fas fa-bars" />
                            </span>
                            Dasboard Menu
                        </div>
                        <div className="container dasboard-container">
                            {/* dashboard-title end */}
                            {/* dasboard-wrapper*/}
                            <div className="dasboard-wrapper fl-wrap no-pag">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div
                                            className="box-widget fl-wrap"
                                        >
                                            <div className="banner-widget fl-wrap">
                                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                                    <div
                                                        className="bg  "
                                                        data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                                    />
                                                </div>
                                                <div className="banner-widget_content">
                                                    <h5>Ideas are easy. Implementation is hard</h5>
                                                    <a
                                                        href="faq"
                                                        className="btn float-btn color-bg small-btn"
                                                    >
                                                        FAQs Listing
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/*box-widget end */}
                                    </div>{" "}
                                    <div className="col-md-8" style={{ marginTop: "20px" }}>
                                        <div className="dasboard-widget-title fl-wrap">
                                            <h5>
                                                <i class="fa-solid fa-question"></i>
                                                Add FAQs
                                            </h5>
                                        </div>
                                        <div className="dasboard-widget-box fl-wrap">
                                            <form>
                                                <div className="custom-form">
                                                    <label>
                                                        Question{" "}
                                                        <span className="dec-icon">
                                                            <i className="fa-solid fa-pen" />
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="enter faq"
                                                        name="query"
                                                        value={formData.query}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                        style={{ width: "100%" }}
                                                    />
                                                    <label>
                                                        Category{" "}
                                                    </label>
                                                    <div className="listsearch-input-item">
                                                        {" "}
                                                        <select
                                                            style={{
                                                                height: "4vh",
                                                                display: "inline",

                                                                padding: "10px",
                                                                borderRadius: "5px",
                                                                border: "1px solid #808080",
                                                                transition: "box-shadow 0.3s ease",
                                                                outline: "none  ",
                                                                width: "780px",
                                                                height: "50px",
                                                                color: "#808080",
                                                                background: "#f5f7fb",
                                                            }}
                                                            name="category"
                                                            value={formData.category}
                                                            onChange={handleChange}
                                                        >
                                                            <option>Select a category</option>
                                                            <option>Buyer</option>
                                                            <option>Seller</option>
                                                            <option>Agency</option>
                                                            <option>Agent</option>
                                                            <option>Listing</option>
                                                            <option>AboutUs</option>
                                                            <option>FAQ</option>
                                                        </select>
                                                    </div>
                                                    <label style={{ marginTop: "20px" }}>Answer </label>
                                                    <textarea
                                                        ref={editorRef}
                                                        cols={40}
                                                        rows={3}
                                                        placeholder="enter answer !"
                                                        style={{ marginBottom: 20, width: "100%" }}
                                                        value={formData.answer}
                                                        name="answer"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                                <button
                                                    className="btn color-bg float-btn m-0 text-white"
                                                    onClick={handleSubmit}
                                                >
                                                    Edit FAQ
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* dasboard-wrapper end */}
                        </div>
                        {/* dashboard-footer */}
                        <div
                            className="dashboard-footer"
                            style={{ left: 0, bottom: "-50px" }}
                        >
                            <div className="dashboard-footer-links fl-wrap">
                                <span>Helpfull Links:</span>
                                <ul>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/about">About</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/pricing">
                                            Pricing Plans
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#main" className="dashbord-totop  custom-scroll-link">
                                <i className="fa-solid fa-caret-up" />
                            </a>
                        </div>
                        {/* dashboard-footer end */}
                    </div>
                    {/* content end */}
                    <div className="dashbard-bg gray-bg" />
                </div>
                {/* wrapper end */}
            </div>
            {/* Main end */}
            {/*=============== scripts  ===============*/}
        </div>
    );
}

export default EditFaq;
