import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';

import Staff from "./component/page/staff";
import Profile from "./component/page/profile";
import Review from "./component/page/review";
import Header from "./component/header/header";
import LoginForm from "./component/page/adminLogin";
import AddMaster from "./component/page/addMaster";
import AddCategory from "./component/page/addCategory";
import AddMasterValue from "./component/page/addMasterValue";
import AddCategoryValue from "./component/page/addCategoryValue";
import { useEffect, useState } from "react";
import Dashboard from "./component/dashboard";
import Blog from "./component/page/blogs";
import AddBlog from "./component/page/AddBlog";
import EditBlog from "./component/page/EditBlog";
import Faq from "./component/page/faq";
import AddFaq from "./component/page/AddFaq";
import EditFaq from "./component/page/EditFaq";
import PropertyEnquiry from "./component/page/AdminComp/PropertyEnquiry";

import Owner from "./component/page/Owner";
import Agent from "./component/page/Agent";
import Agency from "./component/page/Agency";
import Property from "./component/page/Property";
import AddStaff from "./component/page/AddStaff";
import EditStaff from "./component/page/EditStaff";
// import AddCity from "./component/page/addCity";
import CityListing from "./component/page/cityListing";
import Booking from "./component/page/Booking";
import CompleteBooking from "./component/page/CompleteBooking";
import InprogressBooking from "./component/page/InprogressBooking";
import CancelledBooking from "./component/page/CancelledBooking";
import BookedBooking from "./component/page/BookedBooking";
import Testimonials from "./component/page/Testimonials";
// import PricePlaning from "./component/page/AdminComp/PricePlaning";

import AddTestinomials from "./component/page/AddTestinomials";
import MortgageQuery from "./component/page/MortgageQuery";
import AddCity from "./component/page/addCity";
import EditCity from "./component/page/EditCity";
import TextEditor from "./component/page/textEditor";

import Banners from "./component/page/Banners";
import ContactEnquiry from "./component/page/ContactEnquiry";
import AddBanner from "./component/page/AddBanner";
import EditBanner from "./component/page/EditBanner";

const apiUrl = process.env.REACT_APP_URL;
console.log(apiUrl);

const Token = localStorage.getItem("Token");
console.log("Token " + Token);

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [permission, setPermission] = useState([])

  useEffect(() => {
    async function getUser() {
      const url = `${apiUrl}/employee/verify-user`;

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status === "error") {
        navigate("/admin-login");
      } else {
        console.log("verified user");
        setPermission(response?.data?.permission)
        setUser(true);
      }
    }
    getUser();
  }, []);

  return (
    <>
      {user && (
        <div>
          <Header permission={permission} />
        </div>
      )}

      <Routes>
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="staff" element={<Staff />} />
        <Route path="enquiry" element={<PropertyEnquiry />} />
        <Route path="profile" element={<Profile />} />
        <Route path="blog" element={<Blog />} />
        <Route path="faq" element={<Faq />} />
        <Route path="addblog" element={<AddBlog />} />
        <Route path="editblog" element={<EditBlog />} />
        <Route path="addfaq" element={<AddFaq />} />
        <Route path="editfaq" element={<EditFaq />} />
        <Route path="review" element={<Review />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="admin-login" element={<LoginForm />} />
        <Route path="add-master" element={<AddMaster />} />
        <Route path="add-category" element={<AddCategory />} />
        <Route path="master-data" element={<AddMasterValue />} />
        <Route path="category-data" element={<AddCategoryValue />} />

        <Route path="owner" element={<Owner />} />
        <Route path="agent" element={<Agent />} />
        <Route path="agency" element={<Agency />} />
        <Route path="property" element={<Property />} />
        <Route path="addstaff" element={<AddStaff />} />
        <Route path="/:staffId" element={<EditStaff />} />
        {/* <Route path="city" element={<AddCity />} /> */}
        <Route path="profile" element={<Profile />} />
        <Route path="city-listing" element={<CityListing />} />
        <Route path="booking" element={<Booking />} />
        <Route path="complete-booking" element={<CompleteBooking />} />
        <Route path="inprogress-booking" element={<InprogressBooking />} />
        <Route path="cancelled-booking" element={<CancelledBooking />} />
        <Route path="booked-booking" element={<BookedBooking />} />
        <Route path="testimonials" element={<Testimonials />} />
        <Route path="add-testimonials" element={<AddTestinomials />} />
        <Route path="mortgage" element={<MortgageQuery />} />
        <Route path="city" element={<AddCity />} />
        <Route path="editcity" element={<EditCity />} />
        <Route path="edit" element={<TextEditor />} />
        <Route path="banners" element={<Banners />} />
        <Route path="contact-enquiry" element={<ContactEnquiry />} />
        <Route path="addbanner" element={<AddBanner />} />
        <Route path="editbanner" element={<EditBanner />} />


      </Routes>
    </>
  );
}

export default App;
