import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_URL;

const apiKey = process.env.REACT_APP_API_KEY;

const Banners = () => {
    const [data2, setData2] = useState([]);
    const [data1, setData1] = useState([]);
    const navigate = useNavigate();


    const apiUrl = process.env.REACT_APP_URL;
    const Token = localStorage.getItem("Token");
    const apiKey = process.env.REACT_APP_API_KEY;

    function handleDelete(e, id) {
        e.preventDefault()

        if (window.confirm("Do you want to delete")) {
            fetch(`${apiUrl}/banner/delete/` + id, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })
                .then((res) => {
                    alert("Record Deleted");
                    window.location.reload(true)
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }


    function handleChange(e) {
        const { value } = e.target;
        const filteredData = data1.filter((ele) => ele.position === value)
        setData2(filteredData)
    }


    useEffect(() => {
        async function getTableData() {
            try {
                const response = await fetch(`${apiUrl}/banner/getAllBanner`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                    },
                });
                const resData = await response.json();
                if (resData && Array.isArray(resData.data)) {
                    console.log('resData =>', resData)
                    setData2([...resData.data]);
                    setData1([...resData.data]);
                } else {
                    console.log("Response does not contain any array data", resData);
                }
            } catch (error) {
                throw new Error("Failed to fetch Data", error.message);
            }
        }
        getTableData();
    }, [apiUrl, apiKey]);



    return (
        <div>
            {/*=============== basic  ===============*/}
            <meta charSet="UTF-8" />
            <title>Hauzzi Beta Website UI/UX design by Webkype </title>
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content />
            <meta name="description" content />
            {/*=============== css  ===============*/}
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/*=============== favicons ===============*/}
            <link
                rel="shortcut icon"
                href="https://hauzzi.webkype.net/admin/images/favicon.ico"
            />
            <div id="main">
                <div id="wrapper">
                    <div className="dashbard-menu-overlay" />
                    <div className="dashboard-content pt-0">
                        <div className="dashboard-menu-btn color-bg">
                            <span>
                                <i className="fa-solid fa-bars" />
                            </span>
                            Dasboard Menu
                        </div>
                        <div className="container dasboard-container">
                            <div className="dasboard-wrapper fl-wrap">
                                <div className="dasboard-listing-box fl-wrap">
                                    <section className="gray-bg small-padding ">
                                        <div className="container">
                                            <div className="row">

                                                <div className="col-md-4">
                                                    <div
                                                        className="box-widget fl-wrap"
                                                        style={{ marginTop: "70px" }}
                                                    >
                                                        <div className="banner-widget fl-wrap">
                                                            <div className="bg-wrap bg-parallax-wrap-gradien">
                                                                <div
                                                                    className="bg  "
                                                                    data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                                                />
                                                            </div>
                                                            <div className="banner-widget_content">
                                                                <h5>
                                                                    Do you want to join our real estate network?
                                                                </h5>
                                                                <a
                                                                    href="addbanner"
                                                                    className="btn float-btn color-bg small-btn"
                                                                >
                                                                    Add Banner
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="list-main-wrap-header box-list-header fl-wrap">
                                                        <div className="list-main-wrap-title">
                                                            <h2 style={{ fontSize: "16px" }}>
                                                                <span>Banner Listing </span>
                                                                <strong>{data2.length}</strong>
                                                            </h2>
                                                        </div>
                                                        <div className="list-main-wrap-opt">
                                                            <div className="price-opt">
                                                                <span className="price-opt-title">
                                                                    Position:
                                                                </span>
                                                                <div className="listsearch-input-item">
                                                                    <select
                                                                        onChange={(e) => {
                                                                            handleChange(e)
                                                                        }}
                                                                        style={{
                                                                            height: "4vh",
                                                                            display: "inline",
                                                                            padding: "10px",
                                                                            borderRadius: "5px",
                                                                            border: "1px solid #808080",
                                                                            transition: "box-shadow 0.3s ease",
                                                                            outline: "none  ",
                                                                            width: "100%",
                                                                            height: "50px",
                                                                            color: "#808080",
                                                                            background: "#f5f7fb",
                                                                            marginBottom: "10px",
                                                                        }}

                                                                    >
                                                                        <option >Select Position</option>
                                                                        <option value="MainSliderBanner">Main Slider Banner</option>
                                                                        <option value="SecondRightBanner">Second Right Banner</option>
                                                                        <option value="ThirdLeftBanner">Third Left Banner</option>
                                                                        <option value="LeftOfEndBanner">Left Of End Banners</option>
                                                                        <option value="RightOfEndBanner">Right Of End Banners</option>
                                                                    </select>

                                                                    {/* <div>
                                                                        <label htmlFor="itemSelect">Select an item:</label>
                                                                        <select id="itemSelect" value={selectedItem} onChange={handleChange}>
                                                                            <option value="" disabled>Select an item</option>
                                                                            {items.map((item, index) => (
                                                                                <option key={index} value={item}>{item}</option>
                                                                            ))}
                                                                        </select>
                                                                        {selectedItem && <p>You selected: {selectedItem}</p>}
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                                                        <div className="row">
                                                            <div
                                                                className="col-sm-12"
                                                                style={{ display: "inline-grid" }}
                                                            >
                                                                {data2.map((item, index) => (
                                                                    <div
                                                                        className="listing-item"
                                                                        key={index}
                                                                        style={{ width: "100%" }}
                                                                    >
                                                                        <article className="geodir-category-listing fl-wrap" style={{
                                                                            border: " 1px solid #444",
                                                                            borderRadius: "10px"
                                                                        }}
                                                                        >
                                                                            <div className="geodir-category-img fl-wrap" style={{ padding: '0px' }}>
                                                                                <a
                                                                                    href="#"
                                                                                    className="geodir-category-img_item"
                                                                                >
                                                                                    {item?.imageForWeb ?
                                                                                        <img
                                                                                            style={{
                                                                                                height: "100px",
                                                                                                width: "100%",
                                                                                                // backgroundImage: "linear-gradient(to right, transparent, white)"
                                                                                            }}
                                                                                            key={index}
                                                                                            src={`${apiUrl}/uploads/${item.imageForWeb}`}
                                                                                            alt
                                                                                        /> :
                                                                                        <img
                                                                                            style={{
                                                                                                height: "100px",
                                                                                                width: "100%"
                                                                                            }}
                                                                                            key={index}
                                                                                            src={
                                                                                                "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                                                            }
                                                                                            alt
                                                                                        />
                                                                                    }
                                                                                </a>
                                                                            </div>
                                                                            <div className="geodir-category-content fl-wrap" style={{ padding: "10px", height: '100px' }}>
                                                                                <div
                                                                                    className="agent_card-title fl-wrap"
                                                                                    style={{ paddingTop: "0px", display: "flex", justifyContent: 'space-between', alignItems: 'start' }}
                                                                                >
                                                                                    <div>
                                                                                        <h4 style={{ marginBottom: '4px', fontWeight: '700' }}>
                                                                                            {item.imageTitle == ""
                                                                                                ? "image title"
                                                                                                : item.imageTitle.toUpperCase()}
                                                                                        </h4>
                                                                                        <p style={{ color: 'black', padding: '0px' }}>Position : {item.position} </p>
                                                                                        <p style={{ padding: '0px', fontSize: 'smaller' }}>Tagline : {item.imageDescription}</p>
                                                                                    </div>
                                                                                    <ul style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: "end",
                                                                                        alignItems: "center",
                                                                                        gap: "30px",
                                                                                    }}>
                                                                                        {/* <li style={{ cursor: 'pointer' }}>
                                                                                            <div
                                                                                                onClick={(e) => {
                                                                                                    handleDelete(e, item.id)
                                                                                                }}
                                                                                                className="tolt"
                                                                                                data-microtip-position="top-left"
                                                                                                data-tooltip="Is visible"
                                                                                            >
                                                                                                <i className="fa-solid fa-ban" />
                                                                                            </div>
                                                                                        </li> */}
                                                                                        <li style={{ cursor: 'pointer' }}>
                                                                                            <div
                                                                                                onClick={(e) => {
                                                                                                    navigate("/editbanner",
                                                                                                        { state: item }
                                                                                                    )
                                                                                                }}
                                                                                            >
                                                                                                <i className="fa-solid fa-pen-to-square" style={{ color: '#686868' }} />
                                                                                            </div>
                                                                                        </li>
                                                                                        <li style={{ cursor: 'pointer' }}>
                                                                                            <div
                                                                                                onClick={(e) => {
                                                                                                    handleDelete(e, item.id)
                                                                                                }}
                                                                                                className="tolt"
                                                                                                data-microtip-position="top-left"
                                                                                                data-tooltip="Delete"
                                                                                            >
                                                                                                <i className="fa-solid fa-trash-alt" style={{ color: 'red' }} />
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>


                                                                                </div>
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div
                            className="dashboard-footer"
                            style={{ left: 0, bottom: "-50px" }}
                        >
                            <div className="dashboard-footer-links fl-wrap">
                                <span>Helpfull Links:</span>
                                <ul>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/about">About</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/pricing">
                                            Pricing Plans
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#main" className="dashbord-totop  custom-scroll-link">
                                <i className="fa-solid fa-caret-up" />
                            </a>
                        </div>
                    </div>
                    <div className="dashbard-bg gray-bg" />
                </div >
            </div >
        </div >
    );
};

export default Banners;