import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
function Booking() {
  const apiUrl = process.env.REACT_APP_URL;
  const [enqData, setEnqData] = useState([]);
  const token = localStorage.getItem("Token");

  useEffect(() => {
    async function getTableData() {
      try {
        const response = await fetch(`${apiUrl}/enquiry/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await response.json();
        if (resData && Array.isArray(resData.data)) {
          setEnqData(resData.data);
        } else {
          console.log("Response does not contain any array data", resData);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data", error.message);
      }
    }
    getTableData();
  }, []);
  console.log(enqData);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  return (
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      {/* <div className="loader-wrap">
    <div className="loader-inner">
      <svg>
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
            <feColorMatrix
              in="blur"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
              result="gooey"
            />
            <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
          </filter>
        </defs>
      </svg>
    </div>
  </div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          {/* dashbard-menu-wrap end  */}
          {/* content */}
          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fa-solid fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              <div className="dasboard-wrapper fl-wrap">
                <div className="dasboard-listing-box fl-wrap">
                  {/* col-list-wrap */}
                  <section className="gray-bg small-padding ">
                    <div className="container">
                      <div className="row">
                        {/* search sidebar*/}
                        <div className="col-md-4">
                          {/*box-widget*/}
                          <div className="box-widget fl-wrap">
                            <div className="box-widget-title fl-wrap">
                              Latest Booking
                            </div>
                            <div className="box-widget-content fl-wrap">
                              {/*widget-posts*/}
                              <div className="widget-posts  fl-wrap">
                                <ul className="no-list-style">
                                  {enqData.length &&
                                    enqData.slice(0, 4).map((item, index) => (
                                      <li key={index}>
                                        <div className="widget-posts-img">
                                          <a
                                            href={`https://hauzi-user.vercel.app/${item.property.slug}`}
                                          >
                                            <img
                                              src={
                                                item.property === null
                                                  ? "https://media.istockphoto.com/id/1396814518/vector/image-coming-soon-no-photo-no-thumbnail-image-available-vector-illustration.jpg?s=612x612&w=0&k=20&c=hnh2OZgQGhf0b46-J2z7aHbIWwq8HNlSDaNp2wn_iko="
                                                  : `${apiUrl}/uploads/${item.property.photo1}`
                                              }
                                              alt
                                            />
                                          </a>
                                        </div>
                                        <div className="widget-posts-descr agent-post_descr">
                                          <h4>
                                            <a href="agent-single.html">
                                              {item.property == null
                                                ? "Loading..."
                                                : `${item.property.name}`}
                                            </a>
                                          </h4>
                                          <p
                                            style={{
                                              paddingBottom: "0px",
                                              fontSize: "10px",
                                            }}
                                          >
                                            <i class="fa-solid fa-calendar-days"></i>{" "}
                                            Date of Enquiry :{" "}
                                            {formatDate(item.timeOfEnquiry)}
                                          </p>
                                          {/* <a
                                            href={`mailto:${item.email}`}
                                            className="tolt ftr-btn"
                                            data-microtip-position="top-left"
                                            data-tooltip="Email"
                                          >
                                            <i className="fa-solid fa-envelope" />
                                          </a>
                                          <a
                                            href="tel:123-456-7890"
                                            className="tolt ftr-btn"
                                            data-microtip-position="top-left"
                                            data-tooltip="Call Now"
                                          >
                                            <i className="fa-solid fa-phone" />
                                          </a> */}
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              {/* widget-posts end*/}
                              {/* <a
                                href="listing.html"
                                className="btn float-btn color-bg small-btn"
                              >
                                View All Enquiry
                              </a> */}
                            </div>
                          </div>
                          {/*box-widget end */}
                          {/*box-widget*/}
                          <div className="box-widget fl-wrap">
                            <div className="banner-widget fl-wrap">
                              <div className="bg-wrap bg-parallax-wrap-gradien">
                                <div
                                  className="bg  "
                                  data-bg="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                                />
                              </div>
                              <div className="banner-widget_content">
                                <h5>
                                  Do you want to join our real estate network?
                                </h5>
                                {/* <a
                                  href="#"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  Become an Property
                                </a> */}
                              </div>
                            </div>
                          </div>
                          {/*box-widget end */}
                        </div>
                        {/* search sidebar end*/}
                        <div className="col-md-8">
                          {/* list-main-wrap-header*/}
                          <div className="list-main-wrap-header box-list-header fl-wrap">
                            {/* list-main-wrap-title*/}
                            <div className="list-main-wrap-title">
                              <h2>
                                {" "}
                                <span>All Booking</span>
                                <strong>{enqData.length}</strong>
                              </h2>
                            </div>
                            {/* list-main-wrap-title end*/}
                            {/* list-main-wrap-opt*/}
                            <div className="list-main-wrap-opt">
                              {/* price-opt*/}
                              <div className="price-opt">
                                <span className="price-opt-title">
                                  Sort by:
                                </span>
                                <div className="listsearch-input-item">
                                  <select
                                    data-placeholder="Popularity"
                                    className="chosen-select no-search-select"
                                  >
                                    <option>Sort By</option>
                                    <option>Date</option>
                                    <option>Name: A-Z</option>
                                    <option>Name: Z-A</option>
                                  </select>
                                </div>
                              </div>
                              {/* price-opt end*/}
                            </div>
                            {/* list-main-wrap-opt end*/}
                          </div>
                          {/* list-main-wrap-header end*/}
                          {/* dashboard-listings-wrap*/}
                          <div className="listing-item-container fl-wrap">
                            {/* listing-item */}
                            {enqData.length &&
                              enqData.map((item, index) => (
                                <div
                                  className="listing-item has_one_column"
                                  key={index}
                                >
                                  <article className="geodir-category-listing fl-wrap">
                                    <div className="geodir-category-img fl-wrap">
                                      <a
                                        href={`https://hauzi-user.vercel.app/${item.property.slug}`}
                                        className="geodir-category-img_item"
                                      >
                                        <img
                                          style={{
                                            height: "200px",
                                            width: "100%",
                                            objectFit: 'cover'
                                          }}
                                          src={
                                            item.property === null
                                              ? "https://media.istockphoto.com/id/1396814518/vector/image-coming-soon-no-photo-no-thumbnail-image-available-vector-illustration.jpg?s=612x612&w=0&k=20&c=hnh2OZgQGhf0b46-J2z7aHbIWwq8HNlSDaNp2wn_iko="
                                              : `${apiUrl}/uploads/${item.property.photo1}`
                                          }
                                          alt
                                        />
                                        <div className="overlay" />
                                      </a>
                                      <div className="geodir-category-location">
                                        <a
                                          href="#1"
                                          className="map-item tolt"
                                          data-microtip-position="top-left"
                                          data-tooltip="On the map"
                                        >
                                          <i
                                            style={{ color: "#fff" }}
                                            className="fa-solid fa-map-marker-alt"
                                          />{" "}
                                          {item.property == null
                                            ? "address upload soon "
                                            : `${item.property.address1}`}
                                        </a>
                                        <ul
                                          className="list-single-opt_header_cat"
                                          style={{
                                            top: "-130px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <li style={{ width: "120px" }}>
                                            <a
                                              href="#"
                                              className="cat-opt blue-bg"
                                              style={{
                                                backgroundColor: "#40B69E",
                                              }}
                                            >
                                              {/* {status} */}
                                              {item.status}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="#"
                                              className="cat-opt color-bg"
                                            >
                                              {item.property.category}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>

                                      <div className="geodir-category-listing_media-list">
                                        <span>
                                          <i className="fa-solid fa-camera" /> 5
                                        </span>
                                      </div>
                                    </div>
                                    <div className="geodir-category-content fl-wrap">
                                      <div className="geodir-category-content_price">
                                        <h3>
                                          {" "}
                                          {item.property == null
                                            ? "Loading..."
                                            : `${item.property.name}`}{" "}
                                          <span
                                            style={{
                                              color: "#808080",
                                              fontSize: "12px",
                                            }}
                                          ></span>
                                        </h3>
                                      </div>
                                      <p style={{ paddingBottom: "0px" }}>
                                        <i class="fa-regular fa-money-bill-1"></i>{" "}
                                        Price : ${item.property.maxPrice}
                                      </p>
                                      <p style={{ paddingBottom: "0px" }}>
                                        <i className="fa-solid fa-city"></i>{" "}
                                        City : {item.property.cities.name}
                                      </p>
                                      <p style={{ paddingBottom: "0px" }}>
                                        <i class="fa-solid fa-flag-usa"></i>{" "}
                                        State : {item.property.states.name}
                                      </p>

                                      <p style={{ paddingBottom: "0px" }}>
                                        <i class="fa-solid fa-calendar-days"></i>{" "}
                                        Date of Enquiry :{" "}
                                        {formatDate(item?.timeOfEnquiry)}
                                      </p>

                                      <div className="geodir-category-footer fl-wrap">
                                        {/* <a href="#" className="gcf-company">
                                          <img
                                            src={
                                              item?.User?.photo 
                                                ? "https://img.freepik.com/premium-vector/businessman-avatar-cartoon-character-profile_18591-50141.jpg"
                                                : `${apiUrl}/uploads/${item.User.photo}`
                                            }
                                            alt
                                          />
                                          <span>{item.User.name}</span>
                                        </a> */}
                                        <div style={{ marginLeft: "200px" }}>
                                          {" "}
                                          <span>
                                            {/* <i
                                              style={{
                                                padding: "15px",
                                                paddingBottom: "0",
                                              }}
                                              className="fa-solid fa-paperclip"
                                            ></i>{" "} */}
                                            <a
                                              className="btn float-btn color-bg small-btn tolt"
                                              data-microtip-position="top-left"
                                              data-tooltip="Connect To CRM"
                                            >
                                              CRM
                                            </a>
                                          </span>
                                          {/* <span>
                                            <i
                                              className="fa-solid fa-eye"
                                              style={{
                                                padding: "15px",
                                                paddingBottom: "0",
                                              }}
                                            ></i>{" "}
                                          </span> */}
                                          {/* <span>
                                            <i
                                              class="fa-solid fa-phone"
                                              style={{
                                                padding: "15px",
                                                paddingBottom: "0",
                                              }}
                                            ></i>{" "}
                                          </span> */}
                                        </div>

                                        {/* <div
                                          className="listing-rating card-popup-rainingvis tolt"
                                          data-microtip-position="top"
                                          data-tooltip="Good"
                                          data-starrating2={4}
                                        /> */}
                                      </div>
                                    </div>
                                  </article>
                                </div>
                              ))}
                            {/* listing-item end*/}
                            {/* listing-item */}

                            {/* listing-item end*/}
                            {/* listing-item */}

                            {/* listing-item end*/}
                            {/* listing-item */}

                            {/* listing-item end*/}
                          </div>
                          {/* col-md 8 end */}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            {/* dashboard-footer */}
            <div
              className="dashboard-footer"
              style={{ left: 0, bottom: "-50px" }}
            >
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>

    // <div style={{ padding: "100px", marginTop: "100px" }}>
    //   {" "}
    //   <Table striped bordered hover>
    //     <thead>
    //       <tr>
    //         <th>#</th>
    //         <th>Name of Customer</th>
    //         <th>Phone Number</th>
    //         <th>Email Id</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {enqData.map((item) => (
    //         <tr key={item.id}>
    //           <td>{item.id}</td>
    //           <td>{item.name}</td>
    //           <td>{item.phone}</td>
    //           <td>{item.email}</td>
    //         </tr>
    //       ))}
    //     </tbody>
    //   </Table>
    // </div>
  );
}

export default Booking;
