import React from "react";
import { useState, useEffect } from "react";

const apiUrl = process.env.REACT_APP_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const Token = localStorage.getItem("Token");

function Agent() {
  const initialFormData = {
    state: "",
    city: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [data, setdata] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateId, setStateId] = useState("");



  function handleDelete(e, id) {
    e.preventDefault()
    console.log('id=>', id)
    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/employee/employeeDelete/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          alert("Record Deleted");
          window.location.reload(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }


  }



  useEffect(() => {
    fetch(`${apiUrl}/employee/allStates/207`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          console.log("dataaaaaaaaaaa", data.data);
          setStateOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, [apiKey, apiUrl]);

  useEffect(() => {
    fetch(`${apiUrl}/employee/allcities/${stateId}`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          console.log("dataaaaaaaaaaa", data.data);
          setCityOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, [stateId, apiKey, apiUrl]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/profile/getAll?userType=1`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdata(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  console.log(data);
  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header */}

          {/* header end  */}
          {/* header end  */}

          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content pt-0">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
              </div>
              <div className="container dasboard-container">
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* col-list-wrap */}
                    <section className="gray-bg small-padding ">
                      <div className="container">
                        <div className="row">
                          {/* search sidebar*/}
                          <div className="col-md-4">
                            {/*box-widget*/}
                            {/* <div className="list-searh-input-wrap-title fl-wrap">
                              <i className="fa-solid fa-sliders-h" />
                              <span>Search Agent</span>
                            </div>
                            <div
                              className="block-box fl-wrap search-sb"
                              id="filters-column"
                            >
                              
                              <div className="listsearch-input-item">
                                <label>Keywords</label>
                                <input
                                  type="text"
                                  onclick="this.select()"
                                  placeholder="Name , agency..."
                                  defaultValue
                                  style={{ width: "100%" }}
                                />
                              </div>
                              
                              <div className="listsearch-input-item">
                                <label>Country</label>
                                <select className="selectBox">
                                  <option>Spain</option>
                                </select>
                              </div>
                              <div className="listsearch-input-item">
                                <label>State</label>

                                <select
                                  className="selectBox"
                                  name="state"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setStateId(e.target.value);
                                  }}
                                >
                                  <option>Select States</option>
                                  {stateOptions.map((department) => (
                                    <option
                                      key={department.id}
                                      value={department.id}
                                    >
                                      {department.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="listsearch-input-item">
                                <label>Cities</label>
                                <select
                                  className="selectBox"
                                  name="city"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                >
                                  <option>Select Cities</option>
                                  {cityOptions.map((department) => (
                                    <option
                                      key={department.id}
                                      value={department.id}
                                    >
                                      {department.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              
                              <div className="listsearch-input-item">
                                <div className="price-rage-item fl-wrap">
                                  <span className="pr_title">Rating:</span>
                                  <input
                                    type="text"
                                    className="price-range-double"
                                    data-min={1}
                                    data-max={5}
                                    name="price-range2"
                                    data-step={1}
                                    defaultValue={1}
                                    data-prefix="*"
                                  />
                                </div>
                              </div>
                              
                              <div className="msotw_footer">
                                <a
                                  href="#"
                                  className="btn small-btn float-btn color-bg"
                                >
                                  Search Agent
                                </a>
                                <div className="reset-form reset-btn">
                                  {" "}
                                  <i className="fa-solid fa-sync-alt" /> Reset
                                  Filters
                                </div>
                              </div>
                            </div> */}
                            {/*box-widget end */}
                            {/*box-widget*/}
                            <div
                              className="box-widget fl-wrap"
                              style={{ marginTop: "70px" }}
                            >
                              <div className="banner-widget fl-wrap">
                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                  <div
                                    className="bg  "
                                    data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                  />
                                </div>
                                <div className="banner-widget_content">
                                  <h5>
                                    Do you want to join our real estate network?
                                  </h5>
                                  <a
                                    href="property"
                                    className="btn float-btn color-bg small-btn"
                                  >
                                    Property Listing
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/*box-widget end */}
                          </div>
                          {/* search sidebar end*/}
                          <div className="col-md-8">
                            {/* list-main-wrap-header*/}
                            <div className="list-main-wrap-header box-list-header fl-wrap">
                              {/* list-main-wrap-title*/}
                              <div className="list-main-wrap-title">
                                <h2>
                                  <span>All Agent </span>
                                  <strong>{data.length}</strong>
                                </h2>
                              </div>
                              {/* list-main-wrap-title end*/}
                              {/* list-main-wrap-opt*/}
                              <div className="list-main-wrap-opt">
                                {/* price-opt*/}
                                <div className="price-opt">
                                  <span className="price-opt-title">
                                    Sort by:
                                  </span>
                                  <div className="listsearch-input-item">
                                    <select
                                      data-placeholder="Popularity"
                                      className="selectBox"
                                      style={{ backgroundColor: "#fff" }}
                                    >
                                      <option>New Agent</option>
                                      <option>Name: A-Z</option>
                                      <option>Name: Z-A</option>
                                    </select>
                                  </div>
                                </div>
                                {/* price-opt end*/}
                              </div>
                              {/* list-main-wrap-opt end*/}
                            </div>
                            {/* list-main-wrap-header end*/}
                            {/* dashboard-listings-wrap*/}
                            <div className="dashboard-listings-wrap fl-wrap">
                              <div className="row">
                                {/* dashboard-listings-item*/}
                                {data.length &&
                                  data.map((item, index) => (
                                    <div className="col-md-4" key={index}>
                                      {/*  agent card item */}
                                      <div className="listing-item">
                                        <article className="geodir-category-listing fl-wrap">
                                          <div className="geodir-category-img fl-wrap  agent_card">
                                            <a
                                              href="#"
                                              className="geodir-category-img_item"
                                            >
                                              {/* {item.agentDetails.map(
                                                (agentItem, index) => (
                                                  <img
                                                    key={index}
                                                    src={`${apiUrl}/uploads/${agentItem.photo}`}
                                                    alt
                                                  />
                                                )
                                              )} */}
                                              {item.agentDetails.length !==
                                                0 ? (
                                                item.agentDetails.map(
                                                  (userItem, index) => (
                                                    <img
                                                      style={{
                                                        height: "232px",
                                                        width: "232px",
                                                        objectFit: "cover"
                                                      }}
                                                      key={index}
                                                      src={
                                                        userItem.photo == ""
                                                          ? "https://img.freepik.com/premium-vector/businessman-avatar-cartoon-character-profile_18591-50141.jpg"
                                                          : `${apiUrl}/uploads/${userItem.photo}`
                                                      }
                                                      alt
                                                    />
                                                  )
                                                )
                                              ) : (
                                                <img
                                                  style={{
                                                    height: "232px",
                                                    width: "232px",
                                                  }}
                                                  key={index}
                                                  src={
                                                    "https://img.freepik.com/premium-vector/businessman-avatar-cartoon-character-profile_18591-50141.jpg"
                                                  }
                                                  alt
                                                />
                                              )}
                                            </a>
                                          </div>
                                          <div className="geodir-category-content fl-wrap" style={{ height: "240px" }}>
                                            {/* <div
                                              className="card-verified tolt"
                                              data-microtip-position="left"
                                              data-tooltip="Verified"
                                            >
                                              <i className="fa-solid fa-user-check" />
                                            </div> */}
                                            <div className="agent_card-title fl-wrap">
                                              <h4
                                                style={{ marginBottom: "8px", }}
                                              >
                                                {" "}
                                                <i className="fa-solid fa-user" />{" "}
                                                <a href="#">{item.name}</a>
                                              </h4>
                                              <div
                                                style={{
                                                  marginLeft: "10px",
                                                  fontSize: '11px',
                                                  height: '80px',
                                                  marginTop: '15px',
                                                  display: 'grid',
                                                  gap: '5px'
                                                }}
                                              >
                                                <h5>
                                                  <i className="fa-solid fa-phone" />{" "}
                                                  {item.agentDetails.map(
                                                    (agentItem, index) => (
                                                      <a key={index}>
                                                        {agentItem.phone}
                                                      </a>
                                                    )
                                                  )}{" "}
                                                </h5>
                                                <h5>
                                                  <i className="fa-solid fa-envelope" />{" "}
                                                  {item.email}
                                                </h5>

                                                <h5>
                                                  {" "}
                                                  <i className="fa-solid fa-map" />{" "}
                                                  {item.agentDetails.map(
                                                    (agentItem, index) => (
                                                      <a key={index}>
                                                        {agentItem.address}
                                                      </a>
                                                    )
                                                  )}{" "}
                                                </h5>
                                                <h5>
                                                  {" "}
                                                  <i className="fa-solid fa-calendar" />{" "}
                                                  {item.agentDetails.map(
                                                    (agentItem, index) => (
                                                      <a key={index}>
                                                        {formatDate(
                                                          agentItem.updatedAt
                                                        )}
                                                      </a>
                                                    )
                                                  )}{" "}
                                                </h5>
                                              </div>
                                            </div>
                                            <div className="geodir-category-footer fl-wrap">
                                              <div
                                                className="listing-rating card-popup-rainingvis tolt pull-left"
                                                data-microtip-position="top"
                                                data-tooltip="Good"
                                                data-starrating2={4}
                                              />
                                              <a
                                                onClick={(e) => { handleDelete(e, item.id) }}
                                                className="tolt ftr-btn"
                                                data-microtip-position="left"
                                                data-tooltip="Delete"
                                              >
                                                <i className="fa-solid fa-trash" />
                                              </a>
                                              {/* <a
                                                href="#"
                                                className="tolt ftr-btn"
                                                data-microtip-position="left"
                                                data-tooltip="Verify"
                                              >
                                                <i className="fa-solid fa-user-check" />
                                              </a> */}
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                      {/*  agent card item end */}
                                    </div>
                                  ))}

                                {/* dashboard-listings-item end*/}
                              </div>
                            </div>
                            {/* dashboard-listings-wrap end*/}
                            {/* pagination*/}
                            {/* <div className="pagination">
                              <a href="#" className="prevposts-link">
                                <i className="fa fa-caret-left" />
                              </a>
                              <a href="#">1</a>
                              <a href="#" className="current-page">
                                2
                              </a>
                              <a href="#">3</a>
                              <a href="#">4</a>
                              <a href="#" className="nextposts-link">
                                <i className="fa fa-caret-right" />
                              </a>
                            </div> */}
                            {/* pagination end*/}
                          </div>
                          {/* col-md 8 end */}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-50px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div >
          {/* wrapper end */}
        </div >
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div >
      ;
    </>
  );
}

export default Agent;
