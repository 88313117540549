import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "../../index.css";

function EditBlog() {
    const location = useLocation();
    const item = location.state;
    console.log('item =>', item)
    const initialFormData = {
        id: item.id,
        title: item.title,
        content: item.content,
        category: item.category,
        photo: item.photo,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [blogPhoto, setBlogPhoto] = useState(null);
    const editor = useRef(null);
    const [content, setContent] = useState("");
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");


    // A function to handle the change of the input fields
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file") {
            setFormData((prev) => ({ ...prev, [name]: files[0] }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const content1 = editor.current
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const plainText = doc.body.textContent || "";
        setFormData((prev) => ({ ...prev, content: plainText }));
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (formData[key] !== null) {
                    formDataToSend.append(key, formData[key]);
                }
            }
            const response = await fetch(`${apiUrl}/blog/edit`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });
            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            navigate("/blog");
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div>
            {/*=============== basic  ===============*/}
            <meta charSet="UTF-8" />
            <title>Hauzzi Beta Website UI/UX design by Webkype </title>
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content />
            <meta name="description" content />
            {/*=============== css  ===============*/}
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/*=============== favicons ===============*/}
            <link rel="shortcut icon" href="images/favicon.ico" />

            {/* main */}
            <div id="main">
                {/* header */}

                {/* header end  */}
                {/* header end  */}

                {/* wrapper  */}
                <div id="wrapper">
                    {/* dashbard-menu-wrap */}
                    <div className="dashbard-menu-overlay" />
                    {/* content */}

                    <div className="dashboard-content pt-0">
                        <div className="dashboard-menu-btn color-bg">
                            <span>
                                <i className="fas fa-bars" />
                            </span>
                            Dasboard Menu{" "}
                        </div>{" "}
                        <div className="container dasboard-container">
                            {/* dashboard-title end */}
                            {/* dasboard-wrapper*/}{" "}
                            <div className="dasboard-wrapper fl-wrap no-pag">
                                {" "}
                                <div className="row">
                                    <div className="col-md-4">
                                        {/*box-widget*/}
                                        <div
                                            className="box-widget fl-wrap"
                                        // style={{ marginTop: "70px" }}
                                        >
                                            <div className="banner-widget fl-wrap">
                                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                                    <div
                                                        className="bg  "
                                                        data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                                    />
                                                </div>
                                                <div className="banner-widget_content">
                                                    <h5>Ideas are easy. Implementation is hard</h5>
                                                    <a
                                                        href="blog"
                                                        className="btn float-btn color-bg small-btn"
                                                    >
                                                        Blog Listing
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/*box-widget end */}
                                    </div>{" "}
                                    <div className="col-md-8" style={{ marginTop: "20px" }}>
                                        {" "}
                                        <div className="dasboard-widget-title fl-wrap">
                                            {" "}
                                            <h5>
                                                <i className="fa-solid fa-blog" />
                                                Edit Blogs{" "}
                                            </h5>{" "}
                                        </div>{" "}
                                        <div className="dasboard-widget-box fl-wrap">
                                            {" "}
                                            <form>
                                                {" "}
                                                <div className="custom-form">
                                                    {" "}
                                                    <label>
                                                        Title{" "}
                                                        <span className="dec-icon">
                                                            <i className="fa-solid fa-pen" />{" "}
                                                        </span>{" "}
                                                    </label>{" "}
                                                    <input
                                                        type="text"
                                                        placeholder="Enter blog title"
                                                        name="title"
                                                        value={formData.title}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                        style={{ width: "100%" }}
                                                    />
                                                    <label>
                                                        Category{" "}

                                                    </label>

                                                    <div className="listsearch-input-item">
                                                        {" "}
                                                        <select
                                                            name="category"
                                                            value={formData.category}
                                                            onChange={handleChange}
                                                            style={{
                                                                height: "4vh",
                                                                display: "inline",
                                                                padding: "10px",
                                                                borderRadius: "5px",
                                                                border: "1px solid #808080",
                                                                transition: "box-shadow 0.3s ease",
                                                                outline: "none  ",
                                                                width: "780px",
                                                                height: "50px",
                                                                color: "#808080",
                                                                background: "#f5f7fb",
                                                            }}
                                                        >
                                                            <option>Select a category</option>
                                                            <option>Agent</option>
                                                            <option>Property</option>
                                                            <option>Agency</option>
                                                            <option>User</option>
                                                        </select>
                                                    </div>

                                                    <textarea
                                                        cols={40}
                                                        rows={3}
                                                        placeholder="Enter blog content !"
                                                        style={{ marginBottom: 20, width: "100%" }}
                                                        name="content"
                                                        value={formData.content}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                    />

                                                    <input
                                                        type="file"
                                                        // className="upload"
                                                        name="photo"
                                                        onChange={(e) => {
                                                            const files = e.target.files;
                                                            if (files.length > 0) {
                                                                const propertyFile = files[0];
                                                                if (propertyFile.type.startsWith("image/")) {
                                                                    setBlogPhoto(propertyFile);
                                                                    handleChange(e);
                                                                }
                                                            }
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                            marginTop: "20px",
                                                            marginBottom: "30px",
                                                            color: "#808080",
                                                        }}
                                                    />
                                                    {blogPhoto && (
                                                        <img
                                                            src={URL.createObjectURL(blogPhoto)}
                                                            alt="Selected File"
                                                            style={{ maxWidth: "350px" }}
                                                        />
                                                    )}
                                                    {!blogPhoto && (
                                                        <img
                                                            src={`${apiUrl}/uploads/${formData.photo}`}
                                                            alt="Selected File"
                                                            style={{ maxWidth: "350px" }}
                                                        />
                                                    )}
                                                </div>
                                                <button
                                                    className="btn color-bg float-btn m-0 text-white"
                                                    onClick={handleSubmit}
                                                >
                                                    Edit Blog
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* dasboard-wrapper end */}
                        </div>
                        {/* dashboard-footer */}
                        <div className="dashboard-footer" style={{ left: 0 }}>
                            <div className="dashboard-footer-links fl-wrap">
                                <span>Helpfull Links:</span>
                                <ul>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/about">About</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/pricing">
                                            Pricing Plans
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                                    </li>
                                    <li>
                                        <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#main" className="dashbord-totop  custom-scroll-link">
                                <i className="fa-solid fa-caret-up" />
                            </a>
                        </div>
                        {/* dashboard-footer end */}
                    </div>
                    {/* content end */}
                    <div className="dashbard-bg gray-bg" />
                </div>
                {/* wrapper end */}
            </div>
            {/* Main end */}
            {/*=============== scripts  ===============*/}
        </div>
    );
}

export default EditBlog;
